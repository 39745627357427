<!-- eslint-disable ts/no-explicit-any -->
<script lang="ts" setup>
import type { GraphQLError } from "graphql";
import { computed } from "vue";

import VdError from "@/components/structures/VdError/VdError.vue";
import VdNotFound from "@/components/structures/VdNotFound/VdNotFound.vue";

const props = withDefaults(
  defineProps<{
    loading: boolean;
    error?: any;
    data?: any;
    errorText?: string;
    notFoundText?: string;
  }>(),
  {
    error: undefined,
    data: undefined,
    errorText: "We are working on it!",
    notFoundText: "Content not found",
  },
);

/* Handle not found code */
const notFoundError = computed(
  () => !!(props.error?.response?.errors || []).find((err: GraphQLError) => err.extensions?.code === "NOT_FOUND"),
);
</script>

<template>
  <div class="vd-query-status-component">
    <template v-if="loading && $slots.loading">
      <slot name="loading" />
    </template>

    <template v-else-if="error && !notFoundError">
      <VdError :text="errorText" />
    </template>

    <template v-else-if="!data && !loading">
      <VdNotFound :text="notFoundText" />
    </template>

    <template v-else>
      <slot />
    </template>
  </div>
</template>
