<script setup lang="ts">
import { useStorage, useTemplateRefsList } from "@vueuse/core";
import isEqual from "lodash.isequal";
import Button from "primevue/button";
import type { ColumnFilterModelType } from "primevue/column";
import type { ListboxChangeEvent } from "primevue/listbox";
import { computed, nextTick, watch } from "vue";

import type { FilterValues } from "@/composables/use-state-filters";
import { config } from "@/config";

import ListFilter from "./components/ListFilter/ListFilter.vue";
import ListFiltersDropdown from "./components/ListFiltersDropdown/ListFiltersDropdown.vue";
import type { FilterConfig } from "./types";

const props = withDefaults(defineProps<{
  // eslint-disable-next-line ts/no-explicit-any
  filters: FilterValues<any>;
  configFilters: Record<string, FilterConfig>;
  context?: Record<string, unknown>;
  storageId: string;
  loading?: boolean;
}>(), {
  context: () => ({}),
  loading: false,
});

const emit = defineEmits<{
  // eslint-disable-next-line ts/no-explicit-any
  "update:filters": [value: FilterValues<any>];
  "clear": [];
}>();

const configFilters = computed(() => {
  return Object.fromEntries(Object.entries(props.configFilters).filter(([_, filterConfig]) => {
    return filterConfig.visibility?.() ?? true;
  }));
});

const refs = useTemplateRefsList<
  HTMLDivElement & { onToggleOverlay: (e: Event) => void }
>();

function onUpdateFilter(key: string, filter: ColumnFilterModelType) {
  const data = {
    ...props.filters,
    [key]: filter,
  };

  emit("update:filters", data);
}

function onClearFilter(key: string) {
  const data = {
    ...props.filters,
    [key]: { ...configFilters.value[key]?.initialValue },
  };

  emit("update:filters", data);
}

const pinnedFiltersStorage = useStorage(
  `${config.localStoragePrefix}-${props.storageId}-pinned-filters`,
  [] as string[],
);

const alwaysDisplayedFilters = computed<Record<string, FilterConfig>>(() =>
  Object.entries(configFilters.value).reduce<Record<string, FilterConfig>>(
    (acc, [key, filter]) => {
      if (filter.isAlwaysDisplayed) {
        acc[key] = filter;
      }

      return acc;
    },
    {},
  ),
);

const pinnedFilters = computed<FilterConfig[]>(() =>
  pinnedFiltersStorage.value
    /**
     * Return only the filters present in the config.
     * This will prevent weird behavior if the user has
     * unsupported filter in its localstorage
     */
    .filter(key => configFilters.value[key] && !configFilters.value[key]?.isAlwaysDisplayed)
    .map(key => configFilters.value[key])
    .filter(Boolean),
);

const unPinnedFilters = computed<Record<string, FilterConfig>>(() =>
  Object.entries(configFilters.value).reduce<Record<string, FilterConfig>>(
    (acc, [key, filter]) => {
      if (
        !pinnedFiltersStorage.value.includes(key)
        && !filter.isAlwaysDisplayed
      ) {
        acc[key] = filter;
      }

      return acc;
    },
    {},
  ),
);

async function onPinFilter(event: ListboxChangeEvent, key: string) {
  pinnedFiltersStorage.value = [...pinnedFiltersStorage.value, key];

  await nextTick();

  const lastRef = refs.value[refs.value.length - 1];
  lastRef?.onToggleOverlay?.(event.originalEvent);
}

function onUnpinFilter(key: string) {
  onClearFilter(key);
  pinnedFiltersStorage.value = pinnedFiltersStorage.value.filter(
    filter => filter !== key,
  );
}

function onResetFilters() {
  pinnedFiltersStorage.value = [];
  emit("clear");
}

const filledFilters = computed(() =>
  Object.keys(props.filters).filter((key) => {
    if (configFilters.value[key] == null) {
      return false;
    }
    /** the filter is filled if its value is different from its initialValue */
    return !isEqual(props.filters[key].value, configFilters.value[key].initialValue.value);
  }),
);

watch(filledFilters, () => {
  if (filledFilters.value.length > 0) {
    pinnedFiltersStorage.value = [
      ...new Set([...filledFilters.value, ...pinnedFiltersStorage.value]),
    ];
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div class="list-filters-component relative mt-1 flex w-full flex-col gap-2">
    <div
      v-if="loading"
      class="list-filters-overlay absolute inset-0 z-10 bg-white opacity-50"
    />

    <div class="flex w-full items-start justify-between px-4 sm:px-0">
      <div class="flex flex-wrap gap-2">
        <!-- ALWAYS DISPLAYED FILTERS - Here we want the alwaysDisplayed filters first, that's not duplication -->
        <ListFilter
          v-for="filter in alwaysDisplayedFilters"
          :ref="refs.set"
          :key="filter.id"
          :filter="filters[filter.id]"
          :config-filter="filter"
          :context="{ ...context, filtersState: filters }"
          @update:filter="(val) => onUpdateFilter(filter.id, val)"
          @clear="() => onClearFilter(filter.id)"
        />

        <!-- PINNED FILTERS -->
        <ListFilter
          v-for="filter in pinnedFilters"
          :ref="refs.set"
          :key="filter.id"
          data-test="list-filter-pinned"
          :filter="filters[filter.id]"
          :config-filter="filter"
          :context="{ ...context, filtersState: filters }"
          @update:filter="(val) => onUpdateFilter(filter.id, val)"
          @unpin="() => onUnpinFilter(filter.id)"
          @clear="() => onClearFilter(filter.id)"
        />

        <!-- DYNAMIC FILTERS DROPDOWN -->
        <ListFiltersDropdown
          :config-filters="unPinnedFilters"
          append-to="body"
          @select-filter="onPinFilter"
        />

        <Transition name="fade">
          <Button
            v-if="pinnedFiltersStorage.length > 0"
            class="reset-btn shrink-0 p-2"
            label="Reset filters"
            severity="secondary"
            icon="fa-light fa-trash-can"
            text
            data-test="reset-filters-button"
            @click="onResetFilters"
          />
        </Transition>
      </div>
    </div>
  </div>
</template>
