<script setup lang="ts">
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import ColumnsCustomizer from "@/components/business/ColumnsCustomizer/ColumnsCustomizer.vue";
import ListFilters from "@/components/business/ListFilters/ListFilters.vue";
import ReportingDateRangeCalendar from "@/components/business/ReportingDateRangeCalendar/ReportingDateRangeCalendar.vue";
import VdButton from "@/components/structures/VdButton/VdButton.vue";
import VdEmpty from "@/components/structures/VdEmpty/VdEmpty.vue";
import VdPageHeadline from "@/components/structures/VdPageHeadline/VdPageHeadline.vue";
import VdQueryStatus from "@/components/structures/VdQueryStatus/VdQueryStatus.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import VdTabView from "@/components/structures/VdTabView/VdTabView.vue";
import useReportingDateRange from "@/composables/use-reporting-daterange";
import { CAMPAIGN_OBJECTIVE_OPTIONS, type CampaignObjective } from "@/constants";
import type { AppFragment } from "@/graphql";
import { CampaignStatus } from "@/graphql";
import links from "@/router/links";

import CampaignsListTable from "./components/CampaignsListTable/CampaignsListTable.vue";
import { CAMPAIGNS_LIST_PAGE_ID } from "./constants";
import { useColumns } from "./use-columns";
import { useData } from "./use-data";
import { useFilters } from "./use-filters";

const router = useRouter();
const route = useRoute();

const campaignObjective = computed<CampaignObjective | null>(() => route.params.campaignObjective as CampaignObjective);

/**
 * Filters
 */
const { filters, config: configFilters, clear: onClearFilters, filterRows } = useFilters({
  campaignObjective,
});

const showArchived = computed(() =>
  (filters.value.status?.value ?? []).includes(CampaignStatus.Archived),
);

/**
 * Queries
 */
const reportingDateRange = useReportingDateRange();

const {
  rows,
  isCampaignsLoading,
  campaignsError,
  isReportsLoading,
  isReportsError,
} = useData({ reportingDateRange, showArchived });

const filteredRows = computed(() =>
  filterRows(rows.value, campaignObjective.value),
);

/**
 * Others
 */

function onCreateCampaign() {
  router.push({ name: links.campaigns.new });
}

// TODO: deprecated - use metaApps instead
const appOptions = computed(() => {
  const duplicatedApps = rows.value.filter(row => row.__typename === "PerformanceCampaign").map(row => row.app);
  const dedupApps = duplicatedApps
    .filter(Boolean)
    .filter(
      (app, index, all) => all.findIndex(o => o.id === app.id) === index,
    );
  return dedupApps as AppFragment[];
});

/**
 * Columns
 */
const displayColumnPicker = ref(false);
const { selectedColumns, customizableColumns } = useColumns({
  isReportsLoading,
  isReportsError,
});

const tabsItems = computed(() => [
  { label: "All", link: { name: links.campaigns.list }, icon: null, command: () => router.push({ name: links.campaigns.list }) },
  ...CAMPAIGN_OBJECTIVE_OPTIONS.map(option => ({
    label: option.label,
    link: { name: links.campaigns.list, params: { campaignObjective: option.value } },
    command: () => {
      router.push({ name: links.campaigns.list, params: { campaignObjective: option.value } });
    },
    icon: `font-normal fa-light ${option.icon}`,
  })),
]);

const activeTabIndex = computed(() => {
  if (campaignObjective.value === null) {
    return 0;
  }

  return CAMPAIGN_OBJECTIVE_OPTIONS.findIndex(
    option => option.value === campaignObjective.value,
  ) + 1;
});

function getTabsColor(active: boolean) {
  return {
    "text-primary": active,
    "text-color-secondary": !active,
  };
}
</script>

<template>
  <div class="campaigns-list-page" data-test="campaigns-list">
    <VdQueryStatus
      :data="rows"
      :error="campaignsError"
      :loading="isCampaignsLoading"
    >
      <VdPageHeadline title="Campaigns" class="mb-8">
        <template #end>
          <VdButton
            data-test="new-campaign"
            responsive-icon
            label="New campaign"
            icon="fa fa-plus"
            @click="onCreateCampaign"
          />
        </template>
      </VdPageHeadline>

      <div class="white-box layout-box flex flex-col gap-4">
        <VdTabView class="w-full" :active-tab-index="activeTabIndex">
          <div
            v-for="tab in tabsItems"
            :key="tab.label"
            :header="tab.label"
            :link="tab.link"
            :icon="tab.icon"
          >
            {{ tab.label }}
          </div>

          <template #item="{ item, props, active }">
            <VdRouterLink
              :to="item.link"
              v-bind="props.action"
              class="z-10 flex items-center gap-1 p-6 pb-5"
            >
              <span
                v-if="item.icon"
                v-bind="props.icon"
                :class="getTabsColor(active)"
              />

              <span
                v-bind="props.label"
                :class="getTabsColor(active)"
              >
                {{ item.label }}
              </span>
            </VdRouterLink>
          </template>

          <template #header-end>
            <ReportingDateRangeCalendar />
          </template>
        </VdTabView>

        <div class="flex items-start gap-3">
          <ListFilters
            v-model:filters="filters"
            :config-filters="configFilters"
            :loading="isCampaignsLoading"
            :context="{ appOptions }"
            :storage-id="CAMPAIGNS_LIST_PAGE_ID"
            @clear="onClearFilters"
          />

          <Button
            v-tooltip.left="'Customize columns'"
            outlined
            icon="fa-duotone fa-line-columns"
            @click="displayColumnPicker = true"
          />
        </div>

        <CampaignsListTable
          :rows="rows"
          :filtered-rows="filteredRows"
          :selected-columns="selectedColumns"
          :is-loading="isCampaignsLoading"
          :is-reports-loading="isReportsLoading"
          :is-reports-error="isReportsError"
        >
          <template #empty>
            <VdEmpty title="no campaign">
              <p>You don't have created any campaign yet.</p>
              <template #actions>
                <Button
                  label="New campaign"
                  icon="fa fa-plus"
                  class="p-button-primary"
                  @click="onCreateCampaign"
                />
              </template>
            </VdEmpty>
          </template>
        </CampaignsListTable>
      </div>

      <ColumnsCustomizer
        v-model:visible="displayColumnPicker"
        v-model="selectedColumns"
        :columns="customizableColumns"
      />
    </VdQueryStatus>
  </div>
</template>
