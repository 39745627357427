<script lang="ts" setup>
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
/**
 * We need to import the tooltip directive here as the ChartsEvolutionTag
 * is used in a component generated via a separate createApp
 */
import vTooltip from "primevue/tooltip";
import { computed } from "vue";

import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";

const props = defineProps<{
  currentValue?: number;
  lastValue?: number;
  loading?: boolean;
}>();

const spendEvolution = computed(() => {
  if (props.currentValue == null || props.lastValue == null) {
    return null;
  }

  // percentage difference between the current and last value
  return ((props.currentValue - props.lastValue) / props.lastValue) * 100;
});

const evolutionValue = computed(() => {
  if (spendEvolution.value == null) {
    return "-";
  }

  if (spendEvolution.value === 0) {
    return "0%";
  }

  const prefix = spendEvolution.value > 0 ? "+" : "";

  return `${prefix}${spendEvolution.value.toFixed(2)}%`;
});

const tooltipValue = computed(() => {
  if (props.lastValue == null) {
    return "-";
  }

  return `-7 days: ${prettifyCurrency(props.lastValue)}`;
});

const tagClasses = computed(() => {
  if (spendEvolution.value == null) {
    return "";
  }

  return spendEvolution.value >= 0 ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600";
});
</script>

<template>
  <Skeleton
    v-if="loading"
    width="3rem"
    height="1.3rem"
  />

  <Tag
    v-else-if="spendEvolution != null"
    v-tooltip.top="{ value: tooltipValue }"
    class="charts-evolution-tag-component px-2 py-1"
    :class="tagClasses"
    :value="evolutionValue"
  >
    <template #default>
      <div class="flex items-center gap-1">
        <span>{{ evolutionValue }}</span>
      </div>
    </template>
  </Tag>
</template>
