import type { Prettify } from "./type-helpers";

/**
 * Returns a new object with the keys specified omitted
 * @param obj The source object
 * @param keys The keys to omit from the object
 * @returns A new object with the keys specified omitted
 */
export function omit<T extends Record<string, unknown>, K extends keyof T>(obj: T, keys: K[]): Prettify<Omit<T, K>> {
  const result = {} as Omit<T, K>;
  const allKeys = Object.keys(obj) as K[];
  for (const key of allKeys) {
    if (keys.includes(key) === false) {
      result[key as unknown as keyof Omit<T, K>] = obj[key as unknown as keyof Omit<T, K>];
    }
  }
  return result;
}
