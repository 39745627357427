<script lang="ts" setup>
import Button from "primevue/button";
import { computed } from "vue";

import AssetHtmlTypeSelectInput from "@/components/business/AssetHtmlTypeSelectInput/AssetHtmlTypeSelectInput.vue";
import { ASSET_TYPE_INFO_OPTIONS } from "@/constants";
import type { AssetHtmlType } from "@/graphql";
import { AssetType } from "@/graphql";

type AssetTypeValue = AssetType | null;
type AssetHtmlTypeValue = AssetHtmlType | null;

type ValueType = {
  type: AssetTypeValue;
  htmlType: AssetHtmlTypeValue;
};

const props = defineProps<{
  value: ValueType;
}>();

const emit = defineEmits<{
  "update:value": [value: ValueType];
}>();

function onTypeChange(type: AssetTypeValue) {
  const newValue = {
    type,
    htmlType: type === AssetType.Html ? props.value.htmlType : null,
  };
  emit("update:value", newValue);
}

function onHTMLTypeChange(htmlType: AssetHtmlTypeValue) {
  const newValue = {
    type: props.value.type,
    htmlType,
  };
  emit("update:value", newValue);
}

function getButtonClass(tabKey: AssetTypeValue) {
  if (props.value.type === tabKey) {
    return "p-button-primary p-button-outlined asset-type-filter-component__item-active";
  }

  return "p-button-secondary p-button-text";
}

const isHtmlTypeSelected = computed(() => props.value.type === AssetType.Html);

const items = [
  {
    key: null,
    label: "All",
    tooltipText: "All types",
    icon: "fa-regular fa-list",
  },
  ...ASSET_TYPE_INFO_OPTIONS,
];
</script>

<template>
  <div class="asset-type-filter-component flex w-full items-center gap-1">
    <template v-for="menuItem in items" :key="menuItem.key">
      <Button
        v-tooltip.top="{ value: menuItem.tooltipText }"
        class="asset-type-filter-component__item px-3 py-2 uppercase"
        :class="getButtonClass(menuItem.key)"
        :icon="menuItem.icon"
        :style="{ '--color': menuItem.color }"
        :label="menuItem.label"
        @click="() => onTypeChange(menuItem.key)"
      />
    </template>

    <AssetHtmlTypeSelectInput
      v-if="isHtmlTypeSelected"
      :model-value="value.htmlType"
      clearable
      @update:model-value="(val) => onHTMLTypeChange(val)"
    />
  </div>
</template>

<style scoped lang="scss">
.asset-type-filter-component__item {
  &.p-button.p-button-secondary.p-button-text {
    &:hover {
      color: var(--color, var(--primary-color));
    }
  }
}

.asset-type-filter-component__item-active {
  &.p-button {
    color: var(--color, var(--primary-color));
    border: var(--color, var(--primary-color)) 1px solid;
  }
}
</style>
