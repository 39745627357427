import { useRouteQuery } from "@vueuse/router";
import { computed, type MaybeRefOrGetter, type Ref, toValue } from "vue";
import { useRoute, useRouter } from "vue-router";

type UrlStorage<T> = { [K in keyof T]: Ref<string | string[] | null> };

/**
 * Function that will help manage the state of the URL.
 */
export function createUrlStorage<T>(fields: MaybeRefOrGetter<Array<keyof T>>) {
  const route = useRoute();
  const router = useRouter();

  const urlStorage = computed(() => toValue(fields).reduce(
    (acc, fieldName) => {
      const result = {
        ...acc,
        [fieldName as unknown as keyof T]: useRouteQuery(fieldName as unknown as string, undefined, { route, router }),
      };

      return result;
    },
    {} as UrlStorage<T>,
  ));

  function get(): { [K in keyof T]: string | string[] } {
    return toValue(fields).reduce((acc, fieldName) => {
      const key = fieldName as unknown as keyof T;
      if (urlStorage.value[key].value != null) {
        return { ...acc, [key]: urlStorage.value[key].value };
      }
      return acc;
    }, {} as { [K in keyof T]: string | string[] });
  }

  function set(obj: { [K in keyof T]: string | string[] | null } | null): void {
    const globalFields = toValue(fields);
    if (globalFields.length === 0) {
      throw new Error("unexpected error");
    }

    for (const fieldName of globalFields) {
      const key = fieldName as unknown as keyof T;
      if (obj == null) {
        urlStorage.value[key].value = null;
        continue;
      }

      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        urlStorage.value[key].value = obj[key] == null || obj[key] === "" ? null : obj[key];
      }
    }
  }

  function deleteParam(paramName: keyof T): void {
    if (urlStorage.value[paramName] != null) {
      urlStorage.value[paramName].value = null;
    }
  }

  return {
    get,
    set,
    deleteParam,
  };
}
