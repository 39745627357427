import type { Prettify } from "./type-helpers";

/**
 * Create a new object which is a subset of the source object
 * @param obj the object source
 * @param keys keys to pick from obj
 * @returns a new object with only the keys specified
 */
export function pick<T extends Record<string, unknown>, K extends keyof T>(obj: T, keys: K[]): Prettify<Pick<T, K>> {
  const result = {} as Pick<T, K>;
  for (const key of keys) {
    result[key] = obj[key];
  }
  return result;
}
