<script lang="ts" setup>
import TabMenu, { type TabMenuSlots } from "primevue/tabmenu";
import { computed, ref, useSlots, type VNode, watch } from "vue";

const props = defineProps<{
  activeTabIndex?: number;
}>();

defineSlots<{
  "header-end": (props: { activeIndex: number }) => VNode[];
  "default": (props?: { props: { header: string } }) => VNode[];
  "item"?: TabMenuSlots["item"];
}>();

const slots = useSlots();

const activeIndex = ref<number>(0);

watch(() => props.activeTabIndex, (value) => {
  if (value == null) {
    return;
  }

  activeIndex.value = value;
}, { immediate: true });

const items = computed(() => {
  if (!slots || !slots.default?.()) {
    return;
  }

  /**
   * Vue v-for adds a Symbol(v-fgt) element to the slots list,
   * so we need to check if the first element is a Symbol and get its children
   */
  const slotsList = slots.default()[0]?.type.toString() === "Symbol(v-fgt)" ? slots.default()[0]?.children as VNode[] : slots.default();

  if (!slotsList) {
    return;
  }

  const result = slotsList.map((slot) => {
    return {
      label: slot.props?.header,
      link: slot.props?.link,
      icon: slot.props?.icon,
    };
  });

  return result;
});
</script>

<template>
  <div class="vd-tab-view-component flex flex-col">
    <div class="vd-tab-view-tabs sticky top-0 z-10 flex justify-between bg-white">
      <div class="flex-1 overflow-auto">
        <TabMenu
          v-model:active-index="activeIndex"
          class="shrink-0"
          :model="items"
          :pt="{
            root: { class: 'background-transparent' },
            menu: { class: 'border-none' },
            menuitem: { 'class': 'flex-shrink-0 z-10', 'data-test': 'tab-menu' },
            action: { class: 'm-0' },
          }"
        >
          <template v-if="$slots.item" #item="slotAttrs">
            <slot name="item" v-bind="slotAttrs" />
          </template>
        </Tabmenu>
      </div>

      <div class="flex shrink-0 items-center">
        <slot name="header-end" :active-index="activeIndex" />
      </div>

      <div class="vd-tab-view-border absolute bottom-0 h-[2px] w-full" />
    </div>

    <div v-if="$slots?.default?.() && !$slots.item" class="z-0">
      <component :is="$slots.default()[activeIndex]" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vd-tab-view-border {
  background-color: var(--border-color);
}
</style>
