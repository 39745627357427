import { computed, type Ref, ref, watch } from "vue";

import type { ReportingDateRange } from "@/composables/use-reporting-daterange";
import {
  type CampaignsListPageAwarenessCampaignFragment,
  type CampaignsListPageConversionCampaignFragment,
  type CampaignsListPageReportsQueryVariables,
  CampaignStatus,
} from "@/graphql";
import { useCampaignsListPageQuery } from "@/pages/campaigns/CampaignsList/queries/campaigns.query.graphql";
import { useCampaignsListPageReportsQuery } from "@/pages/campaigns/CampaignsList/queries/reporting.query.graphql";
import type { CampaignsListPageCommonCampaignRow } from "@/pages/campaigns/CampaignsList/types";
import { extractDateFromDatetime } from "@/utils/common";

import { blankReport } from "../campaign-details/blank-report";

export type CampaignFragment = CampaignsListPageConversionCampaignFragment | CampaignsListPageAwarenessCampaignFragment;

export function useData({ reportingDateRange, showArchived }: { reportingDateRange: ReportingDateRange; showArchived: Ref<boolean> }) {
  /**
   * Fill this array with the fetched campaigns ids
   * it will be passed to the report query
   */
  const fetchedCampaignsIds = ref<string[]>([]);

  const campaignsListQueryVariables = computed(() => ({
    showTestMode: true,
    sorts: "-creationDate",
    status: [
      /* CampaignStatus.Pending, // TODO */
      CampaignStatus.Running,
      CampaignStatus.Stopped,
      CampaignStatus.Draft,
      ...(showArchived.value ? [CampaignStatus.Archived] : []),
    ],
  }));

  const campaigns = ref<CampaignFragment[]>([]);

  const {
    data,
    isPending: isCampaignsLoading,
    error: campaignsError,
  } = useCampaignsListPageQuery(campaignsListQueryVariables);

  watch(data, () => {
    campaigns.value = data.value?.campaigns?.edges?.map(o => o?.node?.campaign).filter(Boolean) ?? [];
    fetchedCampaignsIds.value = campaigns.value.map(o => o.id);
  }, { deep: true, immediate: true });

  /**
   * Reports
   */

  const isReportingQueryEnabled = computed(
    () => fetchedCampaignsIds.value.length > 0,
  );

  const reportQueryVariables = computed<CampaignsListPageReportsQueryVariables>(() => ({
    startDate: extractDateFromDatetime(reportingDateRange.value.start),
    endDate: extractDateFromDatetime(reportingDateRange.value.end),
    today: extractDateFromDatetime(new Date()),
  }));

  const {
    isPending: isReportsLoading,
    data: reportsData,
    isError: isReportsError,
  } = useCampaignsListPageReportsQuery(reportQueryVariables, {
    enabled: isReportingQueryEnabled,
  });

  const reports = computed(() => {
    if (reportsData.value == null) {
      return null;
    }
    const entries = reportsData.value?.campaigns.edges?.map(o => o?.node).filter(Boolean) ?? [];
    // eslint-disable-next-line ts/no-non-null-assertion
    return Object.fromEntries(entries.map(node => [node.campaign.id, node.report!]));
  });

  const todaySpentBudgetByCampaignId = computed(() => {
    if (reportsData.value == null) {
      return null;
    }
    const entries = reportsData.value?.todaySpentBudgetByCampaignIds.edges?.map(o => o?.node).filter(Boolean) ?? [];
    return Object.fromEntries(entries.map(node => [node.campaign.id, node.report?.billableSpend ?? 0]));
  });

  const rows = computed<CampaignsListPageCommonCampaignRow[]>(() =>
    campaigns.value.map((campaign) => {
      const report = reports.value?.[campaign.id] ?? blankReport;
      const todaySpend = todaySpentBudgetByCampaignId.value?.[campaign.id] ?? 0;

      const dailyBudget = (campaign.__typename === "PerformanceCampaign" ? campaign.totalDailyBudget : campaign.dailyBudget) ?? 0;

      let todaySpendProgression = 0;
      if (dailyBudget && todaySpend) {
        todaySpendProgression = (todaySpend / dailyBudget) * 100;
      }

      if (campaign.__typename === "PerformanceCampaign") {
        return {
          ...campaign,
          ...report,
          dailyBudget,
          todaySpend,
          todaySpendProgression,
          campaignType: campaign.roasType ?? campaign.type,
          adPlacement: campaign.adsManagement.adsPlacement,
          objective: "conversion",
        } satisfies CampaignsListPageCommonCampaignRow;
      }

      return {
        ...campaign,
        ...report,
        dailyBudget,
        todaySpend,
        todaySpendProgression,
        objective: "awareness",
      } satisfies CampaignsListPageCommonCampaignRow;
    }),
  );

  return {
    isCampaignsLoading,
    isReportsLoading,
    isReportsError,
    campaignsError,
    rows,
  };
}
